'use client';
import { Box, Button, Container, Grid, styled, Typography, keyframes } from '@mui/material'
import Link from 'next/link'

// Add animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const HeroStyled = styled(Grid)({
    backgroundColor: "#393E41",
    backgroundImage: `linear-gradient(135deg, rgba(57, 62, 65, 0.97), rgba(14, 64, 45, 0.9))`,
    position: "relative",
    alignItems: "center",
    minHeight: "100vh",
    width: "100%",
    margin: 0,
    overflow: "hidden",
    "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(/images/computer.png)`,
        backgroundPosition: 'center',
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        opacity: 0.15,
        zIndex: 1,
    },
});

const ContentContainer = styled(Container)(({ theme }) => ({
    position: "relative",
    zIndex: 2,
    animation: `${fadeIn} 1s ease-out`,
    padding: theme.spacing(3),
}));

const AnimatedTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'component'
})<{ component?: React.ElementType }>(({ theme }) => ({
    animation: `${fadeIn} 1s ease-out`,
    textShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const GradientText = styled('span')(({ theme }) => ({
    background: `linear-gradient(90deg, #43AD4C, #F34213)`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    color: 'transparent',
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
    animation: `${float} 3s ease-in-out infinite`,
    backgroundColor: '#43AD4C',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        backgroundColor: '#3a9742',
        transform: 'scale(1.05)',
        boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
    },
}));

export default function Hero() {
    return (
        <HeroStyled container>
            <ContentContainer maxWidth="md">
                <Box sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <AnimatedTypography
                        component="h1"
                        variant="h2"
                        color="white"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            mb: 3,
                            fontSize: {
                                xs: '2rem',
                                sm: '2.5rem',
                                md: '3.5rem'
                            },
                            lineHeight: 1.2
                        }}
                    >
                        Potencia tu Negocio con{' '}
                        <GradientText style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit'
                        }}>
                            IA y Tecnología
                        </GradientText>
                    </AnimatedTypography>
                    <AnimatedTypography
                        variant="h5"
                        color="grey.100"
                        paragraph
                        sx={{
                            mb: 4,
                            opacity: 0.9,
                            animation: `${fadeIn} 1s ease-out 0.5s both`,
                            maxWidth: '800px',
                            fontSize: {
                                xs: '1rem',
                                sm: '1.25rem',
                                md: '1.5rem'
                            }
                        }}
                    >
                        Soporte técnico integral y asesoría con soluciones de IA para optimizar
                        tu infraestructura tecnológica 24/7
                    </AnimatedTypography>
                    <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Link href="#booking-form" style={{ textDecoration: 'none' }}>
                            <AnimatedButton
                                variant="contained"
                                size="large"
                                sx={{
                                    py: { xs: 1.5, md: 2 },
                                    px: { xs: 3, md: 4 },
                                    borderRadius: '30px',
                                    fontSize: { xs: '1rem', md: '1.1rem' },
                                    fontWeight: 600
                                }}
                            >
                                Comenzar Ahora
                            </AnimatedButton>
                        </Link>
                    </Box>
                </Box>
            </ContentContainer>
        </HeroStyled>
    )
}
'use client'

import { useState } from 'react'
import {
    Box,
    Container,
    Paper,
    Typography,
    TextField,
    MenuItem,
    Grid,
    Button,
    styled,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
} from '@mui/material'
import { BsCheckCircleFill, BsArrowRight } from 'react-icons/bs'
import { motion, AnimatePresence } from 'framer-motion'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'

const FormContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
    },
    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
}))

const SuccessContainer = styled(motion.div)({
    textAlign: 'center',
    padding: '2rem',
})

const supportTypes = [
    { value: 'hardware', label: 'Hardware', description: 'Problemas con equipos físicos' },
    { value: 'software', label: 'Software', description: 'Instalación, configuración o errores de programas' },
    { value: 'network', label: 'Redes', description: 'Conectividad o configuración de red' },
    { value: 'security', label: 'Seguridad', description: 'Problemas de seguridad o accesos' },
    { value: 'backup', label: 'Respaldo', description: 'Copias de seguridad o recuperación de datos' },
]

const urgencyLevels = [
    { value: 'low', label: 'Baja', description: 'No afecta operaciones diarias' },
    { value: 'medium', label: 'Media', description: 'Afecta parcialmente las operaciones' },
    { value: 'high', label: 'Alta', description: 'Afecta significativamente las operaciones' },
]

export default function BookingForm() {
    const [activeStep, setActiveStep] = useState(0)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [formData, setFormData] = useState({
        // Contact Information
        name: '',
        email: '',
        phone: '',
        company: '',
        // Support Details
        supportType: '',
        description: '',
        urgencyLevel: 'medium',
        // Schedule
        preferredDate: null,
        preferredTime: null,
        alternativeDate: null,
        alternativeTime: null,
        // Additional Info
        remoteSupport: 'yes',
        additionalNotes: '',
    })

    const handleChange = (field: string) => (event: any) => {
        setFormData({ ...formData, [field]: event.target.value })
    }

    const handleDateChange = (field: string) => (value: unknown) => {
        setFormData({ ...formData, [field]: value as Date | null })
    }

    const handleNext = () => {
        setActiveStep((prev) => prev + 1)
    }

    const handleBack = () => {
        setActiveStep((prev) => prev - 1)
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        setIsSubmitting(true)

        try {
            // Simulate API call - replace with your actual submission logic
            await new Promise(resolve => setTimeout(resolve, 2000))
            setIsSuccess(true)
        } catch (error) {
            console.error('Error submitting form:', error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const steps = ['Información de Contacto', 'Detalles del Soporte', 'Programación']

    if (isSuccess) {
        return (
            <Box id="booking-form" sx={{ py: 8, backgroundColor: '#f8f9fa' }}>
                <Container maxWidth="md">
                    <FormContainer elevation={3}>
                        <SuccessContainer
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.5,
                                type: "spring",
                                stiffness: 100
                            }}
                        >
                            <BsCheckCircleFill
                                size={80}
                                style={{
                                    color: '#43AD4C',
                                    marginBottom: '24px'
                                }}
                            />
                            <Typography variant="h4" gutterBottom sx={{ color: '#0E402D' }}>
                                ¡Solicitud Enviada con Éxito!
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                Nos pondremos en contacto contigo pronto para confirmar tu cita.
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setIsSuccess(false)
                                    setActiveStep(0)
                                }}
                                sx={{
                                    backgroundColor: '#43AD4C',
                                    '&:hover': {
                                        backgroundColor: '#3a9742',
                                    },
                                }}
                                endIcon={<BsArrowRight />}
                            >
                                Agendar Otra Cita
                            </Button>
                        </SuccessContainer>
                    </FormContainer>
                </Container>
            </Box>
        )
    }

    return (
        <Box id="booking-form" sx={{ py: 8, backgroundColor: '#f8f9fa' }}>
            <Container maxWidth="md">
                <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        mb: 6,
                        background: 'linear-gradient(90deg, #0E402D, #43AD4C)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                    }}
                >
                    Agenda tu Soporte Técnico
                </Typography>

                <FormContainer elevation={3}>
                    <Stepper activeStep={activeStep} sx={{ mb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <form onSubmit={handleSubmit}>
                        {activeStep === 0 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Nombre completo"
                                        value={formData.name}
                                        onChange={handleChange('name')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange('email')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Teléfono"
                                        value={formData.phone}
                                        onChange={handleChange('phone')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Empresa"
                                        value={formData.company}
                                        onChange={handleChange('company')}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 1 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Tipo de Soporte"
                                        value={formData.supportType}
                                        onChange={handleChange('supportType')}
                                    >
                                        {supportTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Box>
                                                    <Typography variant="subtitle1">{option.label}</Typography>
                                                    <Typography variant="caption" color="text.secondary">
                                                        {option.description}
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Descripción del problema"
                                        value={formData.description}
                                        onChange={handleChange('description')}
                                        helperText="Por favor, describa el problema con el mayor detalle posible"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <Typography variant="subtitle1" gutterBottom>
                                            Nivel de Urgencia
                                        </Typography>
                                        <RadioGroup
                                            row
                                            value={formData.urgencyLevel}
                                            onChange={handleChange('urgencyLevel')}
                                        >
                                            {urgencyLevels.map((level) => (
                                                <FormControlLabel
                                                    key={level.value}
                                                    value={level.value}
                                                    control={<Radio />}
                                                    label={
                                                        <Box>
                                                            <Typography variant="body2">{level.label}</Typography>
                                                            <Typography variant="caption" color="text.secondary">
                                                                {level.description}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 2 && (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            label="Fecha preferida"
                                            value={formData.preferredDate}
                                            onChange={handleDateChange('preferredDate')}
                                            disablePast
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TimePicker
                                            label="Hora preferida"
                                            value={formData.preferredTime}
                                            onChange={handleDateChange('preferredTime')}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            label="Fecha alternativa"
                                            value={formData.alternativeDate}
                                            onChange={handleDateChange('alternativeDate')}
                                            disablePast
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TimePicker
                                            label="Hora alternativa"
                                            value={formData.alternativeTime}
                                            onChange={handleDateChange('alternativeTime')}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <Typography variant="subtitle1" gutterBottom>
                                                ¿Permite soporte remoto?
                                            </Typography>
                                            <RadioGroup
                                                row
                                                value={formData.remoteSupport}
                                                onChange={handleChange('remoteSupport')}
                                            >
                                                <FormControlLabel
                                                    value="yes"
                                                    control={<Radio />}
                                                    label="Sí"
                                                />
                                                <FormControlLabel
                                                    value="no"
                                                    control={<Radio />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            label="Notas adicionales"
                                            value={formData.additionalNotes}
                                            onChange={handleChange('additionalNotes')}
                                            helperText="Información adicional que considere relevante"
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <Button
                                disabled={activeStep === 0 || isSubmitting}
                                onClick={handleBack}
                                variant="outlined"
                            >
                                Atrás
                            </Button>
                            <Button
                                variant="contained"
                                onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: '#43AD4C',
                                    '&:hover': {
                                        backgroundColor: '#3a9742',
                                    },
                                    minWidth: 120,
                                }}
                                endIcon={!isSubmitting && activeStep !== steps.length - 1 ? <BsArrowRight /> : null}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : activeStep === steps.length - 1 ? (
                                    'Enviar'
                                ) : (
                                    'Siguiente'
                                )}
                            </Button>
                        </Box>
                    </form>
                </FormContainer>
            </Container>
        </Box>
    )
}
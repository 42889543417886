'use client';
import { Box, Button, Container, Paper, Typography, Stack, styled } from '@mui/material';
import { BsClock, BsTools, BsArrowRight, BsCheckCircle, BsBuilding } from 'react-icons/bs';
import { motion } from 'framer-motion';

const PlanCard = styled(motion(Paper))(({ theme }) => ({
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
    '&:hover': {
        transform: 'translateY(-8px)',
    },
    transition: 'transform 0.3s ease-in-out',
}));

const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    '& svg': {
        marginRight: theme.spacing(1),
        color: '#43AD4C',
    },
}));

export default function PublicPlans() {
    return (
        <Box id="plans" sx={{ py: 12, backgroundColor: '#fff' }}>
            <Container maxWidth="lg">
                <Box sx={{ mb: 8, textAlign: 'center' }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            background: 'linear-gradient(90deg, #0E402D, #43AD4C)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            mb: 3
                        }}
                    >
                        Planes de Soporte
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ maxWidth: 800, mx: 'auto', mb: 4 }}>
                        Soluciones flexibles que se adaptan a las necesidades de tu empresa
                    </Typography>
                </Box>

                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={4}
                    alignItems="stretch"
                    justifyContent="center"
                >
                    {/* Plan por Visita - Actualizado */}
                    <PlanCard
                        elevation={3}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1 }}
                        sx={{ maxWidth: 400 }}
                    >
                        <Typography variant="h5" gutterBottom sx={{ color: '#0E402D', fontWeight: 600 }}>
                            Soporte por Visita
                        </Typography>
                        <Typography variant="h3" sx={{ mb: 2, color: '#43AD4C', fontWeight: 700 }}>
                            $90.000
                            <Typography component="span" variant="body1" sx={{ color: 'text.secondary' }}>
                                /visita
                            </Typography>
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                            Soporte técnico para usuarios finales y equipos
                        </Typography>

                        <Stack spacing={2} sx={{ flex: 1 }}>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Mantenimiento de computadores</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Solución problemas de software</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">2 horas de soporte en sitio</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Garantía del servicio</Typography>
                            </FeatureItem>

                            {/* Limitaciones */}
                            <Box sx={{ mt: 2, p: 2, backgroundColor: '#f8f9fa', borderRadius: 1 }}>
                                <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                                    No incluye:
                                </Typography>
                                <Stack spacing={1}>
                                    <FeatureItem>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            • Configuración de servidores
                                        </Typography>
                                    </FeatureItem>
                                    <FeatureItem>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            • Infraestructura de red
                                        </Typography>
                                    </FeatureItem>
                                    <FeatureItem>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            • Soporte empresarial
                                        </Typography>
                                    </FeatureItem>
                                </Stack>
                            </Box>
                        </Stack>

                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            href="#booking-form"
                            sx={{ mt: 4 }}
                            endIcon={<BsArrowRight />}
                        >
                            Agendar Visita
                        </Button>
                    </PlanCard>

                    {/* Plan Empresarial - Actualizado */}
                    <PlanCard
                        elevation={4}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        sx={{
                            maxWidth: 400,
                            backgroundColor: '#f8f9fa',
                            border: '2px solid #43AD4C',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 20,
                                right: -35,
                                transform: 'rotate(45deg)',
                                backgroundColor: '#43AD4C',
                                color: 'white',
                                px: 4,
                                py: 0.5,
                                width: '200px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="body2">Recomendado</Typography>
                        </Box>

                        <Typography variant="h5" gutterBottom sx={{ color: '#0E402D', fontWeight: 600 }}>
                            Plan Empresarial
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                mb: 2,
                                color: '#43AD4C',
                                fontWeight: 700,
                                fontSize: { xs: '1.5rem', sm: '1.75rem' }
                            }}
                        >
                            Contacta con un Asesor
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                            Plan personalizado según las necesidades de tu empresa
                        </Typography>

                        <Stack spacing={2} sx={{ flex: 1 }}>
                            <Typography variant="subtitle2" sx={{ color: '#0E402D', fontWeight: 600, mt: 2 }}>
                                Plataforma Siply
                            </Typography>
                            <Box sx={{
                                p: 2,
                                backgroundColor: '#43AD4C20',
                                borderRadius: 1,
                                border: '1px solid #43AD4C40',
                                mb: 2
                            }}>
                                <Stack spacing={1}>
                                    <FeatureItem>
                                        <BsCheckCircle />
                                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                            Acceso completo a la plataforma Siply
                                        </Typography>
                                    </FeatureItem>
                                    <FeatureItem>
                                        <BsCheckCircle />
                                        <Typography variant="body2">
                                            Sistema de tickets para gestión de soporte
                                        </Typography>
                                    </FeatureItem>
                                    <FeatureItem>
                                        <BsCheckCircle />
                                        <Typography variant="body2">
                                            Dashboard de seguimiento de procesos
                                        </Typography>
                                    </FeatureItem>
                                    <FeatureItem>
                                        <BsCheckCircle />
                                        <Typography variant="body2">
                                            Reportes y métricas en tiempo real
                                        </Typography>
                                    </FeatureItem>
                                </Stack>
                            </Box>

                            <Typography variant="subtitle2" sx={{ color: '#0E402D', fontWeight: 600, mt: 2 }}>
                                Soporte Técnico
                            </Typography>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Soporte técnico ilimitado</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Mantenimientos preventivos programados</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Atención prioritaria 24/7</Typography>
                            </FeatureItem>

                            <Typography variant="subtitle2" sx={{ color: '#0E402D', fontWeight: 600, mt: 2 }}>
                                Infraestructura y Redes
                            </Typography>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Gestión de redes y servidores</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Configuración y monitoreo de seguridad</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Respaldo y recuperación de datos</Typography>
                            </FeatureItem>

                            <Typography variant="subtitle2" sx={{ color: '#0E402D', fontWeight: 600, mt: 2 }}>
                                Asesoría Tecnológica
                            </Typography>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Consultoría en compra de equipos y software</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Asesoría en implementación de IA</Typography>
                            </FeatureItem>
                            <FeatureItem>
                                <BsCheckCircle />
                                <Typography variant="body2">Recomendación de soluciones cloud</Typography>
                            </FeatureItem>

                            <Box sx={{
                                mt: 2,
                                p: 2,
                                backgroundColor: '#43AD4C20',
                                borderRadius: 1,
                                border: '1px solid #43AD4C40'
                            }}>
                                <Typography variant="body2" sx={{ color: '#0E402D' }}>
                                    Plan personalizado según el tamaño y necesidades de su empresa.
                                    Incluye acceso a Siply, visitas mensuales programadas y soporte remoto ilimitado.
                                </Typography>
                            </Box>
                        </Stack>

                        <Button
                            variant="contained"
                            size="large"
                            href="https://wa.me/330749222574"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                mt: 4,
                                backgroundColor: '#43AD4C',
                                '&:hover': {
                                    backgroundColor: '#3a9742',
                                },
                            }}
                            endIcon={<BsArrowRight />}
                        >
                            Consultar Plan por WhatsApp
                        </Button>
                    </PlanCard>
                </Stack>
            </Container>
        </Box>
    );
}
'use client';
import { AppBar, Box, Button, Container, Stack, Toolbar, styled, IconButton, Drawer, List, ListItem } from '@mui/material';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { BsList } from 'react-icons/bs';

const HeaderButton = styled(Button)(({ theme }) => ({
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
}));

const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    padding: '8px 16px',
    textTransform: 'none',
    fontWeight: 600,
}));

const MobileMenuItem = styled(ListItem)(({ theme }) => ({
    padding: '16px 24px',
    '&:hover': {
        backgroundColor: 'rgba(67, 173, 76, 0.1)',
    },
}));

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                background: 'linear-gradient(90deg, #0E402D, #43AD4C)',
                backdropFilter: 'blur(8px)',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    px: { xs: 2, sm: 4 }
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: 'space-between',
                        padding: '16px 0',
                        minHeight: '80px'
                    }}
                >
                    <Link href="/" style={{ textDecoration: 'none' }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mr: 4
                        }}>
                            <Image
                                src="/images/Logo_white.png"
                                alt="Sispro Consultores IT"
                                width={150}
                                height={50}
                                style={{
                                    cursor: 'pointer',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    </Link>

                    <Stack
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        sx={{
                            display: { xs: 'none', md: 'flex' }
                        }}
                    >
                        <HeaderButton href="#services">Servicios</HeaderButton>
                        <HeaderButton href="#about-us">Equipo</HeaderButton>

                        <Box sx={{ ml: 4 }}>
                            <Stack direction="row" spacing={2}>
                                <ActionButton
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => signIn(undefined, { callbackUrl: '/v1/welcome' })}
                                >
                                    Acceder
                                </ActionButton>
                                <ActionButton
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#0E402D',
                                        '&:hover': {
                                            backgroundColor: '#0a3021',
                                        }
                                    }}
                                    href="#booking-form"
                                >
                                    Agendar Soporte
                                </ActionButton>
                            </Stack>
                        </Box>
                    </Stack>

                    <IconButton
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            color: 'white'
                        }}
                        onClick={toggleMobileMenu}
                    >
                        <BsList size={24} />
                    </IconButton>

                    <Drawer
                        anchor="right"
                        open={mobileMenuOpen}
                        onClose={toggleMobileMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': {
                                width: '100%',
                                maxWidth: '300px',
                                background: 'linear-gradient(180deg, #0E402D, #43AD4C)',
                            }
                        }}
                    >
                        <List sx={{ mt: 2 }}>
                            <MobileMenuItem>
                                <Button
                                    fullWidth
                                    href="#services"
                                    onClick={toggleMobileMenu}
                                    sx={{ color: 'white' }}
                                >
                                    Servicios
                                </Button>
                            </MobileMenuItem>
                            <MobileMenuItem>
                                <Button
                                    fullWidth
                                    href="#about-us"
                                    onClick={toggleMobileMenu}
                                    sx={{ color: 'white' }}
                                >
                                    Equipo
                                </Button>
                            </MobileMenuItem>
                            <MobileMenuItem>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="inherit"
                                    href="/v1/welcome"
                                    onClick={toggleMobileMenu}
                                    sx={{ color: 'white' }}
                                >
                                    Acceder
                                </Button>
                            </MobileMenuItem>
                            <MobileMenuItem>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={toggleMobileMenu}
                                    href="#booking-form"
                                    sx={{
                                        backgroundColor: '#0E402D',
                                        '&:hover': {
                                            backgroundColor: '#0a3021',
                                        }
                                    }}
                                >
                                    Agendar Soporte
                                </Button>
                            </MobileMenuItem>
                        </List>
                    </Drawer>
                </Toolbar>
            </Container>
        </AppBar>
    );
}